import { environment } from '../../../environments/environment';

export const Url = {
    AUTHENTICATION: environment.api + '/sequation-authentication-api',
    CONTENT_LIBRARY: environment.api + '/sequation-content-management-api',
    PROFILE: environment.api + '/sequation-usermanagement-composer-api',
    USER_MANAGEMENT: environment.api + '/sequation-user-management-api',
    ASSESSMENT: environment.api + '/sequation-assessment-api',
    PLAN: environment.api + '/sequation-plan-api',
    JOBS : environment.api + '/sequation-job-api',
    SRM: environment.api + '/sequation-srm-api',
    CVB: environment.api + '/sequation-cv-builder-api',
    DOCUMENT:  environment.api +'/sequation-document-api',
    ORGANIZATION: environment.api + '/sequation-organization-api',
    MOJ_PEF: environment.api + '/sequation-moj-pef-contract-api',
    MOODLE_URL: environment.moodleUrl + '/iomad',
    SEQUATION_MOODLE_API: environment.api + '/sequation-moodle-api',
    ANALYTICS:  environment.api + '/sequation-analytics-api',
    WORKSHOP_MANAGEMENT: environment.api + '/sequation-moj-pef-workshop-api'
};
